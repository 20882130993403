export const rwdrc = {
  sm: {
    min: 0,
    max: 1024,
  },
  ipad: {
    min: 600,
    max: 1024,
  },
  lg: {
    min: 1024,
    max: 8192,
  },
} as const

export const rwdrc2 = {
  phone: {
    max: 600,
  },
  tablet: {
    min: 600,
    max: 1200,
  },
  desktop: {
    min: 1200,
  },
} as const
